import logo from '../assets/images/logo_words.png';

const state = {
  ui: {
    logoUrl: logo,
    footerVisible: true,
  },
};

export default state;
