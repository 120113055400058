module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BuniTek","short_name":"BuniTek","start_url":"/","background_color":"#efefef","theme_color":"#373333","display":"standalone","icon":"src/assets/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ab0cbd70120fef8617f043ec198759a2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TSTRR96","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"function () {\n          return {\n            pageType: window.pageType,\n          }\n        }"},"dataLayerName":"dataLayer","routeChangeEventName":"PageViewG"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
